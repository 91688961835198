.is--home {
  .rwd002-container {
    margin-top: 0;
  }
  h2 {
    strong, small {
      display: block;
      line-height: 1;
    }
    strong {
      @include Barlow(400);
      letter-spacing: .05em;
    }
    small {
      font-weight: 500;
      letter-spacing: .03em;
    }
  }

  @include pc {
    .container {
      max-width: unset;
      padding-inline: 100px;
    }
  }

  .mainvisual {
    position: relative;
    .img_wrap {
      clip-path: inset(0 100% 0 0);
    }
    .text_wrap {
      position: absolute;
      left: 130px;
      width: min(61%, 842px);
      opacity: 0;
      .text {
        color: #fff;
        font-size: 19px;
        font-weight: 700;
        line-height: 1.9;
        letter-spacing: .04em;
        margin-top: 22px;
      }
      @include pc {
        bottom: 50%;
        .text {
          position: absolute;
          left: 0;
          top: 100%;
          white-space: nowrap;
        }
      }
    }
    .president {
      position: absolute;
      right: 0;
      bottom: -79px;
      width: 382px;
      z-index: 1;
      display: block;
      opacity: 0;
      transform: translateY(30px);
    }

    @include pc {
      height: 600px;
      .img_wrap {
        display: grid;
        grid-template-columns: 60% 1fr;
        grid-template-rows: repeat(2, 1fr);
        margin-inline: 100px 150px;
        height: 100%;
        .img01 {
          grid-column: 1 / 2;
          grid-row: 1 / 3;
        }
        .img02 {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
        .img03 {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }
        picture {
          overflow: hidden;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &.loaded {
      .img_wrap {
        clip-path: inset(0);
        transition: clip-path .3s linear .3s;
      }
      .text_wrap {
        opacity: 1;
        transition: opacity .8s .8s;
      }
      .president {
        opacity: 1;
        transform: translateY(0);
        transition: opacity .2s cubic-bezier(0, 0, 0.33, 1.36) .6s , transform .3s cubic-bezier(0, 0, 0.33, 1.36) .6s;
      }
    }
  }

  .block-news {
    h2 {
      display: flex;
      align-items: flex-end;
      column-gap: 10px;
      border-bottom: 1px solid #D4D4D4;
      padding-bottom: 10px;
      height: 80px;
      strong, small {
        color: #fff;
      }
      strong {
        font-size: 36px;
      }
      small {
        font-size: 12px;
      }
    }
    .cate_list {
      padding: 14px 20px 14px 0;
      li {
        line-height: 2;
      }
      a {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .06em;
        &:hover {
          opacity: .8;
        }
      }
    }
    .news_list {
      li {
        border-bottom: 1px solid #D4D4D4;
        @include pc {
          &:nth-of-type(n + 4){
            display: none;
          }
        }
      }
      a {
        padding-block: 20px 26px;
        display: block;
        .info {
          display: flex;
          align-items: center;
          column-gap: 12px;
        }
        .date {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: .06em;
          color: #A7ABAF;
        }
        .cate {
          padding-inline: 10px;
          display: grid;
          place-content: center;
          height: 20px;
          background-color: #929192;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: .1em;
        }
        .ttl {
          margin-top: 6px;
          font-weight: 500;
          letter-spacing: .06em;
          transition: $transition-base;
        }
        &.is-new .ttl::after {
          content: 'NEW';
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 20px;
          background-color: #BADA2B;
          color: #fff;
          font-size: 14px;
          letter-spacing: .05em;
          margin-left: 10px;
          @include pc {
            @include Barlow(400);
          }
        }
        &:hover .ttl {
          color: $theme-green;
        }
      }
    }
    .btn_wrap {
      a {
        display: grid;
        place-content: center;
        width: 100%;
        height: 55px;
        background-color: #282828;
        color: #fff;
        @include Barlow(600);
        font-size: 14px;
        letter-spacing: .05em;
        &:hover {
          background-color: #535353;
        }
      }
    }
    @include pc {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 350px;
        height: calc(100% + 95px);
        background-color: #009F1F;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      .container {
        display: grid;
        grid-template-columns: 250px 1fr;
        column-gap: 45px;
        position: relative;
      }
      h2 {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }
      .cate_list {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
      .news_list {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        
      }
      .btn_wrap {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }

    }
  }

  .block-business {
    margin-top: 165px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 200px);
      height: calc(100% + 158px);
      background-color: #F4F4F4;
      left: 0;
      right: 0;
      margin: auto;
      top: -73px;
      z-index: -2;
    }
    h2 {
      text-align: center;
      strong {
        font-size: 40px;
      }
      small {
        margin-top: 15px;
        font-size: 16px;
      }
    }
    .business_list {
      margin-top: 40px;
      a {
        display: grid;
        place-content: center;
        place-items: center;
        position: relative;
        padding-inline: 15px;
        box-sizing: border-box;
        &::before {
          content: '';
          display: block;
          position: absolute;
          inset: 0;
          z-index: -1;
          opacity: .9;
          transition: $transition-base;
        }
        picture {
          display: block;
          position: absolute;
          inset: 0;
          z-index: -2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &.business1::before { background-color: #3864EF;}
        &.business2::before { background-color: #68C800;}
        &.business3::before { background-color: #00C46C;}
        &.business4::before { background-color: #FF7F5B;}
        &.business5::before { background-color: #0FD5F0;}
        &.business6::before { background-color: #F1BA00;}
      }
      figure {
        display: grid;
        place-content: center;
        width: 85px;
        height: 80px;
        transition: $transition-base;
        .icon1 { width: 63px;}
        .icon2 { width: 85px;}
        .icon3 { width: 62px;}
        .icon4 { width: 72px;}
        .icon5 { width: 76px;}
        .icon6 { width: 77px;}
      }
      dl {
        margin-top: 15px;
        dt {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 5px;
          span {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: .05em;
            line-height: 1;
          }
        }
        dd {
          color: #fff;
          font-size: clamp(14px, 1.5vw, 16px);
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.6;
          margin-inline: auto;
          display: flex;
          align-items: center;
        }
      }
      .more {
        display: grid;
        place-content: center;
        width: 70px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        border: 1px solid #fff;
        box-sizing: border-box;
        @include Barlow(600);
        font-size: 14px;
        letter-spacing: .05em;
        color: #fff;
        transition: $transition-base;
        margin: 12px auto 0;
      }
      a:hover {
        &::before {
          opacity: .85;
        }
        figure {
          transform: scale(1.1);
        }
        .more {
          background-color: #fff;
          &.more1 { color: #3864EF;}
          &.more2 { color: #68C800;}
          &.more3 { color: #00C46C;}
          &.more4 { color: #FF7F5B;}
          &.more5 { color: #0FD5F0;}
          &.more6 { color: #F1BA00;}
        }
      }

      @include pc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        a {
          height: 400px;
          padding-inline: 15px;
        }
        figure {
          width: 85px;
          height: 80px;
          .icon1 { width: 63px;}
          .icon2 { width: 85px;}
          .icon3 { width: 62px;}
          .icon4 { width: 72px;}
          .icon5 { width: 76px;}
          .icon6 { width: 77px;}
        }
        dl {
          margin-top: 15px;
          dt {
            height: 49px;
          }
          dd {
            font-size: clamp(14px, 1.5vw, 16px);
            max-width: 333px;
            height: 77px;
          }
        }
      }
    }
  }

  .block-case {
    position: relative;
    margin-top: 193px;
    h2 {
      strong {
        font-size: 40px;
      }
      small {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    .slide_wrap {
      margin-top: 42px;
      padding-bottom: 70px;
      position: relative;
      .swiper {
        margin-right: -100px;
      }
      .swiper-slide {
        width: 305px;
        a {
          display: block;
          figure {
            overflow: hidden;
            aspect-ratio: 147 / 100;
            position: relative;
            img {
              transition: .3s;
              object-fit: cover;
              min-width: 100%;
              min-height: 100%;
            }
            figcaption {
              display: grid;
              place-content: center;
              min-width: 110px;
              height: 30px;
              background-color: #fff;
              padding-inline: 10px;
              box-sizing: border-box;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: .1em;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
          .text_wrap {
            margin-top: 25px;
            .ttl {
              font-weight: 700;
              line-height: 1.5;
              letter-spacing: .12em;
            }
            .tag_wrap {
              margin-top: 15px;
              display: flex;
              flex-wrap: wrap;
              gap: 10px 15px;
              li {
                line-height: 1;
                font-size: 10px;
                color: #7E7E7E;
              }
            }
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      .arrow_wrap {
        width: 115px;
        height: 22px;
        position: absolute;
        left: calc(50% + 450px);
        bottom: 0;
        @include max(1200px){
          left: auto;
          right: -50px;
        }
        .swiper-button-prev, .swiper-button-next {
          width: 50px;
          height: 22px;
          border: 1px solid $font-color;
          border-radius: 0;
          top: auto;
          bottom: 0;
          &::after {
            content: '';
            width: 14px;
            height: 8px;
            background: url(/assets/img/common/arrow.svg) no-repeat 0 / contain;
            inset: 0;
            margin: auto;
            transition: .3s;
          }
          &:hover{
            background-color: $font-color;
            &::after {
              transform: translateX(3px);
              background-image: url(/assets/img/common/arrow_wh.svg);
            }
          }
        }
        .swiper-button-prev {
          left: 0;
          transform: scaleX(-1);
        }
        .swiper-button-next {
          right: 0;
          &::after {
  
          }
        }
      }
      .swiper-pagination {
        width: min(calc(100% - 200px), 700px);
        height: 2px;
        bottom: 0;
        background-color: #CACDCF;
        top: auto;
        left: 0;
        right: 0;
        margin: auto;
        .swiper-pagination-progressbar-fill {
          background-color: $theme-green;
        }
      }
    }
    .btn_wrap {
      margin-top: 58px;
      a {
        display: grid;
        place-content: center;
        width: 240px;
        height: 50px;
        border: 1px solid $font-color;
        box-sizing: border-box;
        margin-inline: auto;
        @include Barlow(600);
        font-size: 14px;
        letter-spacing: .05em;
        &:hover {
          color: #fff;
          background-color: $font-color;
        }
      }
    }
  }

  .block-link {
    margin-top: 108px;
    a {
      display: grid;
      place-content: center;
      position: relative;
      overflow: hidden;
      height: 337px;
      picture {
        position: absolute;
        inset: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: $transition-base;
        }
      }
      &.about p {
        text-align: center;
        strong, small {
          display: block;
          line-height: 1;
          color: #fff;
        }
        strong {
          @include Barlow(400);
          font-size: 45px;
          letter-spacing: .05em;
        }
        small {
          font-weight: 500;
          letter-spacing: 0;
          margin-top: 10px;
        }
      }
      &.blog p {
        width: 284px;
      }

      &:hover picture img {
        transform: scale(1.1);
      }
    }
    @include pc {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @include sp { 
    .mainvisual {
      .text_wrap {
        left: 35px;
        top: 19%;
        width: auto;
        opacity: 0;
        .text {
          font-size: 12px;
          line-height: 1.5;
          margin-top: 22px;
        }
      }
      .president {
        bottom: -55px;
        width: 192px;
      }

    }

    .block-news {
      margin-top: -2px;
      h2 {
        column-gap: 10px;
        border-bottom: none;
        padding: 0 35px 10px;
        height: 58px;
        margin-inline: -35px;
        background-color: #009F1F;
        position: relative;
        strong {
          font-size: 29px;
        }
        small {
          font-size: 10px;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 70px);
          height: 1px;
          background-color: #D4D4D4;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .cate_list {
        padding: 25px 35px 30px;
        margin-inline: -35px;
        background-color: #009F1F;
        display: flex;
        flex-wrap: wrap;
        gap: 7px 20px;
        li {
          line-height: 1;
        }
        a {
          font-size: 11px;
        }
      }
      .news_list {
        border-top: 1px solid #D4D4D4;
        margin-top: 30px;
        li {
          border-bottom: 1px solid #D4D4D4;
        }
        a {
          padding-block: 20px 26px;
          display: block;
          .info {
            display: flex;
            align-items: center;
            column-gap: 12px;
          }
          .date {
            font-size: 13px;
            color: #062C9C;
          }
          .cate {
            min-width: 140px;
            font-size: 10px;
          }
          .ttl {
            margin-top: 6px;
            font-size: 13px;
            line-height: 2;
          }
          &.is-new .ttl::after {
            background-color: #EE7C36;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: .1em;
            margin-left: 6px;
          }
        }
      }
      .btn_wrap {
        margin-top: 33px;
        a {
          max-width: 200px;
          height: 44px;
          font-size: 11px;
          margin-inline: auto;
        }
      }
    }

    .block-business {
      margin-top: 78px;
      &::before {
        top: -24px;
        width: calc(100% - 70px);
        height: calc(100% + 89px);
      }
      h2 {
        strong {
          font-size: 32px;
        }
        small {
          font-size: 13px;
          margin-top: 8px;
        }
      }
      .business_list {
        margin-top: 40px;
        li {
  
        }
        a {
          height: auto;
          padding-block: 49px 25px;
        }
        figure {
          width: 69px;
          height: 64px;
          .icon1 { width: 50px;}
          .icon2 { width: 69px;}
          .icon3 { width: 50px;}
          .icon4 { width: 58px;}
          .icon5 { width: 61px;}
          .icon6 { width: 62px;}
        }
        dl {
          margin-top: 27px;
          dt {
            line-height: 1;
            span {
              font-size: 18px;
            }
          }
          dd {
            line-height: 1.6;
            max-width: 292px;
            margin-top: 18px;
          }
        }
        .more {
          width: 56px;
          font-size: 11px;
          margin-top: 22px;
        }
      }
    }

    .block-case {
      margin-top: 120px;
      h2 {
        strong {
          font-size: 32px;
        }
        small {
          font-size: 8px;
        }
      }
      .slide_wrap {
        margin-top: 26px;
        padding-bottom: 50px;
        .swiper {
          margin-right: -35px;
        }
        .swiper-slide {
          width: 305px;
          a {
            figure {
              aspect-ratio: 147 / 100;
              figcaption {
                min-width: 88px;
                height: 24px;
                padding-inline: 10px;
                font-size: 11px;
              }
            }
            .text_wrap {
              margin-top: 20px;
              .ttl {
                font-size: 12px;
                letter-spacing: 0;
              }
              .tag_wrap {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                gap: 10px 15px;
                li {
                  line-height: 1;
                  font-size: 10px;
                  color: #7E7E7E;
                }
              }
            }
            &:hover {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
        .arrow_wrap {
          width: 92px;
          height: 18px;
          right: 0;
          .swiper-button-prev, .swiper-button-next {
            width: 40px;
            height: 18px;
            &::after {
              width: 12px;
              height: 7px;
            }
          }
          .swiper-button-prev {
            left: 0;
            transform: scaleX(-1);
          }
          .swiper-button-next {
            right: 0;
          }
        }
        .swiper-pagination {
          width: calc(100% - 110px);
          bottom: 8px;
          right: auto;
        }
      }
      .btn_wrap {
        margin-top: 58px;
        a {
          display: grid;
          place-content: center;
          width: 240px;
          height: 50px;
          border: 1px solid $font-color;
          box-sizing: border-box;
          margin-inline: auto;
          @include Barlow(600);
          font-size: 14px;
          letter-spacing: .05em;
        }
      }
    }

    .block-link {
      margin-top: 68px;
      a {
        height: 180px;
        &.about p {
          strong {
            font-size: 36px;
          }
          small {
            margin-top: 10px;
            font-size: 13px;
          }
        }
        &.blog p {
          width: 224px;
        }
      }
    } 
  }
}