.rwd002-container {

}

body.is--privacy, body.is--sitemap {
  .rwd002-moduletitle {
    display: none;
  }
}

body.is--privacy, body.is--sitemap, body.is--inquiry {
  footer {
    margin-top: 100px;
    @include sp {
      margin-top: 70px;
    }
  }
}