body {
  color: $font-color;

  #allbox {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
    font-weight: 500;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-weight: 400;
    color: $font-color;
    letter-spacing: .12em;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .5s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

.rwd002-block-freearea {
  margin: 0;
  padding: 0;
  &__inner {
    max-width: unset;
  }
}

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  @include sp {
    padding-inline: 35px;
  }
}

.content_header:not(.service_header) {
  height: 243px;
  padding-top: 103px;
  h2 {
    text-align: center;
    position: relative;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: .22em;
    line-height: 1;
    padding-bottom: 28px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 83px;
      height: 1px;
      background-color: $theme-green;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  @include sp {
    height: 135px;
    padding-top: 42px;
    h2 {
      font-size: 19px;
      padding-bottom: 17px;
      &::before {
        width: 50px;
      }
    }
  }
}