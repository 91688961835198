body.is--service {
  .rwd002-detail {
    margin: 0;
    padding: 0;
    &__entry {
      max-width: unset;
      padding: 0;
      margin: 0;
    }
    p {
      line-height: 1.7;
      font-weight: 500;
      letter-spacing: .05em;
      @include sp {
        font-size: 13px;
      }
    }
  }

  .pagelink {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1px;
    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      place-content: center;
      row-gap: 5px;
      height: 90px;
      padding-inline: 10px;
      background-color: #A7ABAF;
      span {
        white-space: nowrap;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0;
      }
    }
    li:nth-of-type(1) a:hover { background-color: #386BED;}
    li:nth-of-type(2) a:hover { background-color: #68C600;}
    li:nth-of-type(3) a:hover { background-color: #00C46C;}
    li:nth-of-type(4) a:hover { background-color: #FF7F5B;}
    li:nth-of-type(5) a:hover { background-color: #0FD5F0;}
    li:nth-of-type(6) a:hover { background-color: #F1BA00;}
  }
  &.is--article-11 .pagelink li:nth-of-type(1) a { background-color: #386BED;}
  &.is--article-12 .pagelink li:nth-of-type(2) a { background-color: #68C600;}
  &.is--article-13 .pagelink li:nth-of-type(3) a { background-color: #00C46C;}
  &.is--article-14 .pagelink li:nth-of-type(4) a { background-color: #FF7F5B;}
  &.is--article-15 .pagelink li:nth-of-type(5) a { background-color: #0FD5F0;}
  &.is--article-16 .pagelink li:nth-of-type(6) a { background-color: #F1BA00;}

  .content_header {
    display: grid;
    place-content: center;
    height: 150px;
    background-color: $font-color;
    h2 {
      @include Barlow(400);
      font-size: 40px;
      letter-spacing: .04em;
      color: #fff;
    }
  }

  .block-main {
    margin-top: 78px;
    h3 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: .1em;
      line-height: 1.2;
      text-align: center;
      position: relative;
      padding-bottom: 28px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 83px;
        height: 1px;
        background-color: $theme-green;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    picture {
      display: block;
      margin-top: 60px;
    }
  }

  .service_ttl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .2em;
    position: relative;
    padding-bottom: 25px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 82px;
      height: 1px;
      background-color: $theme-green;
      left: 0;
      bottom: 0;
    }
    &.ttl_center {
      text-align: center;
      padding-bottom: 12px;
      &::before {
        right: 0;
        margin: auto;
      }
    }
  }

  .link_bottom {
    margin-top: 71px;
  }

  @include sp {
    .pagelink {
      grid-template-columns: repeat(2, 1fr);
      a {
        height: 50px;
        span {
          font-size: 12px;
        }
      }
    }
  
    .content_header {
      height: 100px;
      h2 {
        font-size: 24px;
        letter-spacing: .05em;
      }
    }
  
    .block-main {
      margin-top: 44px;
      h3 {
        font-size: 19px;
        letter-spacing: .22em;
        padding-bottom: 15px;
        &::before {
          width: 83px;
          height: 1px;
        }
      }
      picture {
        margin: 35px -35px 0;
      }
    }
  
    .service_ttl {
      font-size: 19px;
      letter-spacing: .22em;
      padding-bottom: 18px;
      @include max(395px){
        letter-spacing: .15em;
      }
      @include max(380px){
        letter-spacing: .1em;
      }
      &::before {
        width: 50px;
      }
      &.ttl_center {
        margin-inline: -20px;
      }
    }

    .sp_scroll {
      color: $theme-green;
      margin-inline: auto;
      width: 115px;
      line-height: 1 !important;
      padding-bottom: 11px;
      border-bottom: 1px solid $theme-green;
      position: relative;
      font-size: 13px;
      letter-spacing: .03em;
      svg {
        position: absolute;
        width: 14px;
        height: 8px;
        right: 0;
        bottom: 14px;
        stroke: $theme-green;
        fill: none;
        stroke-miterlimit: 10;
      }
    }

    .link_bottom {
      margin-top: 50px;
    }
  }

  // ↑↑ 共通 ↑↑

  &.is--article-11 {
    .block-main .lead_wrap {
      margin-top: 80px;
      position: relative;
      h4 {
        color: $theme-green;
        font-size: 32px;
        letter-spacing: .22em;
        font-weight: 500;
        line-height: 1;
        strong {
          display: block;
          font-size: 48px;
          font-weight: 500;
          font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
          margin-top: 20px;
        }
      }
      p {
        margin-top: 42px;
      }
      picture {
        display: block;
        @include pc {
          position: absolute;
          right: 0;
          bottom: -20px;
          width: 335px;
          z-index: -1;
        }
      }
    }

    .block-first {
      margin-top: 120px;
      .unit {
        + .unit {
          margin-top: 80px;
        }
      }
      p {
        margin-top: 21px;
      }
      picture {
        display: block;
        margin-top: 46px;
      }
      @include pc {
        .shape {
          margin: 12px auto 0;
          max-width: 805px;
        }
      }
    }

    .block-list {
      margin-top: 80px;
      .main_list {
        margin-top: 42px;
        > li {
          + li {
            margin-top: 38px;
            padding-top: 34px;
            border-top: 1px solid #E3E3E3;
          }
        }
        @include pc {
          > li {
            display: grid;
            grid-template-columns: 86px 1fr;
            column-gap: 40px;
          }
          .num {
            margin-top: 5px;
          }
        }
        h5 {
          font-size: 30px;
          font-weight: 700;
          line-height: 1.3;
          letter-spacing: .22em;
          color: $theme-green;
          a {
            font-weight: 700;
            color: $theme-green;
            border-bottom: 1px solid $theme-green;
            &:hover {
              opacity: .7;
            }
          }
        }
        p {
          margin-top: 20px;
        }
        ul {
          margin-top: 26px;
          li {
            line-height: 1.7;
            font-weight: 500;
            letter-spacing: .05em;
            display: flex;
            column-gap: 5px;
            &::before {
              content: '◯';
            }
            @include sp {
              font-size: 13px;
            }
          }
        }
      }

      .breadcrumbs {
        margin-top: 80px;
      }
    }

    .breadcrumbs {
      margin-top: 80px;
    }

    @include sp {
      .block-main .lead_wrap {
        margin-top: 54px;
        h4 {
          font-size: 16px;
          strong {
            font-size: 25px;
            margin-top: 8px;
          }
        }
        p {
          margin-top: 28px;
        }
        picture {
          margin: 20px 0 0 auto;
          width: 218px;
        }
      }
  
      .block-first {
        margin-top: 60px;
        .unit {
          + .unit {
            margin-top: 51px;
          }
        }
        p {
          margin-top: 30px;
        }
        picture {
          margin-top: 22px;
        }
        .shape {
          margin-top: 20px;
        }
      }
  
      .block-list {
        margin-top: 40px;
        .main_list {
          margin-top: 33px;
          > li {
            display: block;
            + li {
              margin-top: 20px;
              padding-top: 30px;
            }
          }
          .num {
            width: 69px;
            display: block;
          }
          .desc {
            margin-top: 20px;
          }
          h5 {
            font-size: 24px;
          }
          p {
            margin-top: 10px;
          }
          ul {
            margin-top: 23px;
            li {
              font-size: 13px;
            }
          }
        }
  
        .breadcrumbs {
          margin-top: 39px;
        }
      }

      .breadcrumbs {
        margin-top: 44px;
      }
    }
  }

  &.is--article-12 {
    .block-main .lead {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.7;
      letter-spacing: .05em;
      text-align: center;
      margin-top: 67px;
    }

    .block-content {
      margin-top: 85px;
      .btn_list {
        display: grid;
        grid-template-columns: repeat(2, 340px);
        column-gap: 40px;
        justify-content: center;
        .select_btn {
          display: grid;
          place-content: center;
          width: 100%;
          height: 90px;
          background-color: #A7ABAF;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: .2em;
          color: #fff;
          border: none;
          transition: $transition-base;
        }
        .sale_btn {
          &:hover, &.current {
            background-color: #68C800;
          }
        }
        .buy_btn {
          &:hover, &.current {
            background-color: $theme-green;
          }
        }
        &.btn_top {
          .select_btn {
            border-radius: 10px 10px 0 0;
          }
        }
        &.btn_bottom {
          .select_btn {
            border-radius: 0 0 10px 10px;
          }
        }
      }

      .outer {
        .content_wrap {
          display: none;
          padding-block: 75px 115px;
          box-shadow: 0 0 10px rgba(0, 0, 0, .4);
          &.current {
            display: block;
          }
        }
      }

      .sale_wrap {
        background-color: #E8F7D9;
        .area1 {
          .lead {
            margin: 43px auto 0;
            max-width: 720px;
            font-size: 16px;
          }
          ul {
            margin-top: 96px;
            p {
              margin-top: 34px;
              strong {
                display: block;
                font-size: 22px;
                font-weight: 700;
                color: #EE7C36;
              }
            }
            @include pc {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 60px;
              position: relative;
              &::before {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: #A7ABAF;                
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
              }
            }
          }
        }

        .area2 {
          margin-top: 110px;
          ul {
            margin-top: 78px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 68px 15px;
            picture {
              display: block;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              line-height: 1.6;
              letter-spacing: .05em;
              text-align: center;
              margin-top: 25px;
            }
          }
        }
      }

      .buy_wrap {
        background-color: #E0F3E1;

        .area1 {
          h5 {
            margin: 58px auto 0;
            max-width: 612px;
          }
          .lead {
            margin-top: 57px;
            text-align: center;
            line-height: 1;
          }
          > picture {
            display: block;
            margin-top: 51px;
          }
        }

        .area2 {
          margin-top: 85px;
          h4 {
            font-size: 26px;
            font-weight: 700;
            line-height: 1.7;
            letter-spacing: .05em;
            text-align: center;
            strong {
              color: $theme-green;
            }
          }
          .lead {
            margin-top: 5px;
            font-size: 16px;
            text-align: center;
          }
          .flow_wrap {
            margin-top: 110px;
            h5 {
              font-size: 20px;
              font-weight: 500;
              line-height: 1;
              letter-spacing: .2em;
              text-align: center;
            }
            .img_wrap {
              margin: 65px auto 0;
              max-width: 800px;
            }
          }
        }
      }

      .btn_wrap {
        margin-top: 134px;
        a {
          display: grid;
          place-content: center;
          margin-inline: auto;
          max-width: 550px;
          height: 110px;
          background-color: $theme-green;
          color: #fff;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: .05em;
          position: relative;
          svg {
            position: absolute;
            width: 16px;
            height: 9px;
            right: 40px;
            top: 0;
            bottom: 0;
            margin: auto;
            stroke: #fff;
            fill: none;
            stroke-miterlimit:10;
            transition: $transition-base;
          }
          &:hover {
            background-color: #95D84C;
            svg {
              transform: translateX(5px);
            }
          }
        }
      }
    }

    .breadcrumbs {
      margin-top: 80px;
    }

    @include sp {
      .block-main .lead {
        font-size: 20px;
        line-height: 1.5;
        margin-top: 45px;
      }
  
      .block-content {
        margin-top: 54px;
        .btn_list {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 15px;
          margin-inline: 25px;
          .select_btn {
            height: 54px;
            font-size: 14px;
          }
          &.btn_top {
            .select_btn {
              border-radius: 6px 6px 0 0;
            }
          }
          &.btn_bottom {
            .select_btn {
              border-radius: 0 0 6px 6px;
            }
          }
        }
  
        .outer {
          .content_wrap {
            padding-block: 40px 60px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .2);
          }
        }
  
        .sale_wrap {
          .area1 {
            .lead {
              margin-top: 36px;
              font-size: 13px;
            }
            ul {
              margin-top: 59px;
              li + li {
                margin-top: 43px;
              }
              picture {
                display: block;
                margin-inline: -8px;
              }
              p {
                margin-top: 20px;
                strong {
                  font-size: 15px;
                }
              }
            }
          }
  
          .area2 {
            margin-top: 52px;
            ul {
              margin: 45px -20px 0;
              grid-template-columns: repeat(2, 1fr);
              gap: 30px 0;
              p {
                font-size: 11px;
                line-height: 1.4;
                margin-top: 20px;
              }
            }
          }
        }
  
        .buy_wrap {
          .area1 {
            h5 {
              margin: 36px -20px 0;
            }
            .lead {
              margin-top: 34px;
              font-size: 13px;
            }
            > picture {
              display: block;
              margin: 25px -20px 0;
            }
          }
  
          .area2 {
            margin-top: 61px;
            h4 {
              font-size: 18px;
              margin-inline: -20px;
            }
            .lead {
              margin-top: 15px;
              font-size: 13px;
              line-height: 1.7;
            }
            .flow_wrap {
              margin-top: 110px;
              h5 {
                font-size: 16px;
              }
              .sp_scroll {
                margin-top: 35px;
              }
              .img_wrap {
                margin: 38px -35px 0 0;
                overflow: auto;
                padding-right: 35px;
                picture {
                  display: block;
                  width: 564px;
                }
              }
            }
          }
        }
  
        .btn_wrap {
          margin-top: 67px;
          a {
            height: 66px;
            font-size: 14px;
            svg {
              width: 10px;
              height: 6px;
              right: 30px;
            }
          }
        }
      }

      .breadcrumbs {
        margin-top: 47px;
      }
    }
  }

  &.is--article-13 {
    .block-lead {
      margin-top: 70px;
      .lead_wrap {
        h4 {
          font-size: 30px;
          font-weight: 700;
          letter-spacing: .05em;
          line-height: 1.5;
          text-align: center;
        }
        p {
          margin-top: 50px;
        }
      }

      .flow_wrap {
        margin-top: 78px;
        h5 {
          line-height: 1;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: .2em;
          text-align: center;
        }
        .item {
          margin-top: 13px;
          line-height: 1;
          font-size: 14px;
          text-align: center;
        }
        .scroll_wrap {
          margin-top: 20px;
        }
      }

      .match_wrap {
        margin-top: 110px;
        .text {
          margin-top: 46px;
        }
        .scroll_wrap {
          margin-top: 42px;
        }
      }

      @include pc {
        p {
          max-width: 720px;
          margin-inline: auto;
        }
        .match_wrap .scroll_wrap {
          margin-bottom: -200px;
        }
      }
    }

    .block-howto {
      padding-block: 270px 95px;
      background-color: #F4F4F4;

      .howto_list {
        margin-top: 69px;
        h5 {
          font-size: 28px;
          font-weight: 700;
          line-height: 1;
          letter-spacing: .05em;
          strong {
            color: $theme-green;
          }
        }
        p {
          margin-top: 19px;
        }
        @include pc {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          box-sizing: border-box;
          li {
            &:first-of-type {
              padding-right: 25px;
            }
            &:nth-of-type(2) {
              padding-left: 25px;
              border-left: 1px solid #D4D4D4;
            }
            &:nth-of-type(n + 3){
              grid-column: 1 / 3;
              margin-top: 40px;
              padding-top: 40px;
              border-top: 1px solid #D4D4D4;
            }
          }
        }
      }

      picture {
        display: block;
        margin-top: 48px;
      }

      .btn_wrap {
        margin-top: 54px;
        a {
          display: grid;
          place-content: center;
          border: 1px solid $theme-green;
          color: $theme-green;
          width: 240px;
          height: 50px;
          font-weight: 500;
          letter-spacing: .05em;
          margin-inline: auto;
          &:hover {
            color: #fff;
            background-color: $theme-green;
          }
        }
      }
    }

    .block-result {
      padding-top: 65px;
      .result_lead {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.9;
        letter-spacing: .05em;
        text-align: center;
      }
      .service_ttl {
        margin-top: 90px;
      }

      .result_list {
        margin-top: 75px;
        @include pc {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 80px 30px;
        }
        a {
          picture {
            display: block;
            overflow: hidden;
            img {
              transition: $transition-base;
            }
          }
          dl {
            margin-top: 20px;
            dt {
              text-align: center;
              text-decoration: underline;
              transition: $transition-base;
              font-size: 18px;
              font-weight: 700;
              line-height: 1.3;
              letter-spacing: .05em;
            }
            dd {
              margin-top: 22px;
              font-size: 15px;
              font-weight: 500;
              line-height: 1.8;
              letter-spacing: .05em;
            }
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
            dl dt {
              color: $theme-green;
            }
          }
        }
      }
    }

    .breadcrumbs {
      margin-top: 123px;
    }

    @include sp {
      .block-lead {
        margin-top: 56px;
        padding-bottom: 58px;
        .lead_wrap {
          h4 {
            font-size: 20px;
          }
          p {
            margin-top: 38px;
          }
        }
  
        .flow_wrap {
          margin-top: 69px;
          h5 {
            font-size: 16px;
          }
          .item {
            margin-top: 13px;
            font-size: 11px;
          }
          .sp_scroll {
            margin-top: 25px;
          }
          .scroll_wrap {
            margin-top: 35px;
            overflow: auto;
            margin-right: -35px;
            padding-right: 35px;
            picture {
              display: block;
              width: 765px;
            }
          }
        }
  
        .match_wrap {
          margin-top: 90px;
          .text {
            margin-top: 35px;
          }
          .sp_scroll {
            margin-top: 33px;
          }
          .scroll_wrap {
            margin-top: 30px;
            margin-right: -35px;
            padding-right: 35px;
            overflow: auto;
            picture {
              display: block;
              width: 680px;
            }
          }
        }

      }
  
      .block-howto {
        padding-block: 48px 77px;
  
        .howto_list {
          margin-top: 42px;
          li + li {
            margin-top: 25px;
            padding-top: 25px;
            border-top: 1px solid #D4D4D4;
          }
          h5 {
            font-size: 17px;
            strong {
              display: block;
              margin-top: 10px;
            }
          }
          p {
            margin-top: 12px;
          }
        }
  
        picture {
          margin: 36px -15px 0;
        }
  
        .btn_wrap {
          margin-top: 36px;
          a {
            width: 192px;
            height: 40px;
            font-size: 13px;
          }
        }
      }
  
      .block-result {
        padding-top: 60px;
        .result_lead {
          font-size: 18px;
          margin-inline: -20px;
        }
        .service_ttl {
          margin-top: 70px;
        }
  
        .result_list {
          margin-top: 42px;
          li + li {
            margin-top: 43px;
          }
          a {
            dl {
              margin-top: 20px;
              dt {
                font-size: 18px;
                line-height: 1.3;
                letter-spacing: .05em;
              }
              dd {
                margin-top: 17px;
                font-size: 15px;
                line-height: 1.8;
                letter-spacing: .05em;
              }
            }
          }
        }
      }
  
      .breadcrumbs {
        margin-top: 64px;
      }
    }

  }

  &.is--article-14 {
    --theme-orange: #FF7F5B;
    .rwd002-detail__entry {
      strong {
        color: var(--theme-orange);
      }
    }
    .block-lead {
      margin-top: 75px;
      padding-bottom: 100px;
      h4 {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        line-height: 1.5;
        letter-spacing: .05em;
      }
      .lead_logo {
        margin: 75px auto 0;
        width: 120px;
        display: block;
      }
      h5 {
        margin-top: 32px;
      }
      .lead_text {
        margin-top: 40px;
      }
      .company_list {
        margin-top: 78px;
        li {
          padding: 35px 40px;
          box-sizing: border-box;
          + li {
            margin-top: 40px;
          }
        }
        h6 {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 1;
        }
        .unit {
          margin-top: 40px;
          picture {
            display: block;
          }
          @include pc {
            display: grid;
            grid-template-columns: 360px 1fr;
          }
        }
        .info_wrap {
          margin-top: 7px;
          p {
            font-size: 14px;
            margin-top: 28px;
          }
        }
        dl {
          max-width: 480px;
          dt {
            font-size: 22px;
            font-weight: 700;
            line-height: 1.6;
            letter-spacing: .05em;
          }
          dd {
            font-weight: 500;
            line-height: 1.7;
            letter-spacing: .05em;
            margin-top: 16px;
          }
        }
      }

      .key {
        border: 1px solid #157D7A;
        h6 {
          color: #157D7A;
        }
        .logo {
          width: 238px;
        }
      }
      .asset {
        border: 1px solid #68C800;
        h6 {
          color: #68C800;
        }
        .logo {
          width: 320px;
        }
      }
    }

    .block-about {
      background-color: #F4F4F4;
      padding-top: 95px;

      .about_list {
        margin-top: 145px;
        li {
          position: relative;
          + li {
            margin-top: 156px;
          }
        }

        .ttl_wrap {
          position: absolute;
          background-color: var(--theme-orange);
          width: 300px;
          aspect-ratio: 1 / 1;
          padding: 35px 28px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          top: -64px;
          z-index: 1;
          .point {
            @include Barlow(500);
            letter-spacing: .05em;
            line-height: 1;
            color: #fff;
            font-size: 23px;
          }
          h5 {
            color: #fff;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.8;
            letter-spacing: .16em;
          }
        }

        .desc {
          background-color: #fff;
          padding-block: 55px;
          position: relative;
          p {
            line-height: 1.8;
          }
          .icon {
            position: absolute;
            z-index: 1;
          }
        }
      }

      @include pc {
        .container {
          max-width: 1186px;
        }

        .lead_text {
          margin: 60px auto 0;
          max-width: 720px;
        }

        .about_list {
          margin-top: 145px;
          li {
            padding-inline: 60px;
            display: grid;
            grid-template-columns: calc(100% - 120px);
            &:nth-of-type(2n + 1){
              justify-content: flex-end;
              .ttl_wrap {
                left: 0;
              }
              .desc {
                padding-inline: 180px 60px;
              }
            }
            &:nth-of-type(2n){
              .ttl_wrap {
                right: 0;
              }
              .desc {
                padding-inline: 60px 180px;
              }
            }
          }
        }

        .about1 {
          .desc {
            display: grid;
            grid-template-columns: 1fr 63px;
            column-gap: 57px;
            .icon {
              position: relative;
            }
          }
        }

        .about2 .desc {
          @include pc {
            @include max(1129px){
              display: flex;
              flex-direction: column-reverse;
              align-items: center;
              row-gap: 20px;
              picture {
                max-width: 360px;
              }
              .space {
                display: none;
              }
            }
            @include min(1130px){
              picture {
                clear: both;
                float: right;
                width: 320px;
                transform: scale(1.38) translate(-23px, 24px);
                transform-origin: left top;
                aspect-ratio: 160 / 77;
              }
              .space {
                width: 0px;
              }
            }
          }
        }

        .about3 {
          .ttl_wrap {
            padding-bottom: 60px;
          }
          .desc_ttl {
            display: flex;
            column-gap: 10px;
            flex-wrap: wrap;
            span {
              font-size: 26px;
              font-weight: 700;
              line-height: 1.5;
              letter-spacing: .2em;
            }
            strong {
              @include Barlow(500);
              font-size: 87px;
              letter-spacing: .05em;
              line-height: 1;
              margin-top: -5px;
            }
          }
          .note {
            font-size: 15px;
            line-height: 1.3;
            margin-top: 24px;
          }
          .ranking {
            margin-top: 15px;
            display: block;
          }
          .icon {
            width: 138px;
            left: -68px;
            top: 198px;
          }
        }

        .about4 .icon {
          width: 181px;
          right: -20px;
          bottom: -79px;
        }

        .about5 {
          .ttl_wrap {
            padding-right: 15px;
          }
          .icon {
            width: 200px;
            left: -57px;
            bottom: 0;
          }
        }
      }
    }

    .breadcrumbs {
      padding-block: 105px 65px;
      background-color: #F4F4F4;
    }

    .link_bottom {
      margin-top: 0;
    }

    @include sp {
      .block-lead {
        margin-top: 60px;
        padding-bottom: 80px;
        h4 {
          font-size: 20px;
        }
        .lead_logo {
          margin-top: 48px;
          width: 86px;
        }
        h5 {
          margin-top: 40px;
        }
        .lead_text {
          margin-top: 33px;
        }
        .company_list {
          margin: 52px -10px 0;
          li {
            padding: 30px 15px;
            + li {
              margin-top: 44px;
            }
          }
          h6 {
            font-size: 14px;
            letter-spacing: 0;
          }
          .unit {
            margin-top: 34px;
            picture {
              display: block;
            }
          }
          .info_wrap {
            margin-top: 0;
            p {
              font-size: 11px;
              line-height: 1.8;
              margin-top: 24px;
            }
          }
          dl {
            margin-top: 25px;
            dt {
              font-size: 17px;
              line-height: 1.7;
            }
            dd {
              line-height: 1.8;
              font-size: 13px;
              margin-top: 22px;
            }
          }
        }
  
        .key {
          .logo {
            width: 218px;
          }
        }
        .asset {
          .logo {
            width: 273px;
          }
        }
      }
  
      .block-about {
        padding-top: 64px;
  
        .lead_text {
          margin-top: 40px;
        }
  
        .about_list {
          margin: 107px -35px 0;
          li {
            + li {
              margin-top: 150px;
            }
            &:nth-of-type(2n + 1){
              justify-content: flex-end;
              padding-left: 25px;
              .ttl_wrap {
                left: 0;
              }
              .desc {
                padding-inline: 15px 35px;
              }
            }
            &:nth-of-type(2n){
              padding-right: 25px;
              .ttl_wrap {
                right: 0;
              }
              .desc {
                padding-inline: 35px 15px;
              }
            }
          }
  
          .ttl_wrap {
            width: 240px;
            padding: 30px 10px 25px 25px;
            top: -64px;
            .point {
              font-size: 19px;
            }
            h5 {
              font-size: 19px;
              letter-spacing: .2em;
            }
          }
  
          .desc {
            padding-block: 210px 47px;
            p {
              line-height: 1.8;
            }
            .icon {
              position: absolute;
              z-index: 1;
            }
          }
        }
  
        .about1 {
          .icon {
            width: 51px;
            left: 248px;
            top: 24px;
          }
        }
  
        .about2 .desc {
          padding-bottom: calc(28px + 27vw);
          picture {
            width: calc(100vw - 40px);
            left: 20px;
            right: 0;
            bottom: -15vw;
            position: absolute;
            display: block;
          }
        }
  
        .about3 {
          margin-top: calc(115px + 17vw) !important;
          .ttl_wrap {
            padding-bottom: 60px;
          }
          .desc {
            padding-bottom: 18px;
          }
          .desc_ttl {
            display: flex;
            align-items: center;
            column-gap: 5px;
            margin-right: -10px;
            span {
              font-size: 15px;
              font-weight: 700;
              line-height: 1.4;
              letter-spacing: .2em;
              @include max(380px){
                font-size: 14px;
                letter-spacing: .1em
              }
            }
            strong {
              @include Barlow(500);
              font-size: 48px;
              letter-spacing: .05em;
              line-height: 1;
              margin-top: -5px;
            }
          }
          .note {
            font-size: 12px;
            line-height: 1.8;
            margin-top: 16px;
          }
          .ranking {
            margin-block: 12px 15px;
            display: block;
            width: 235px;
          }
          .icon {
            width: 110px;
            left: 195px;
            top: 22px;
          }
        }
  
        .about4 {
          .desc {
            padding-bottom: 86px;
          }
          .icon {
            width: 145px;
            left: 96px;
            bottom: -73px;
          }
        }
  
        .about5 {
          .ttl_wrap h5 {
            letter-spacing: .1em;
          }
          .desc {
            padding-top: 230px;
          }
          .icon {
            width: 160px;
            left: 166px;
            top: 46px;
          }
        }
      }
  
      .breadcrumbs {
        padding-block: 47px 70px;
      }
    }
  }

  &.is--article-15 {
    .block-main .lead_wrap {
      margin-top: 64px;
      h4 {
        text-align: center;
        strong, small {
          display: block;
          line-height: 1;
        }
        strong {
          @include Barlow(400);
          font-size: 60px;
          letter-spacing: .05em;
        }
        small {
          margin-top: 18px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: .05em;
        }
      }
      p {
        max-width: 720px;
        margin: 48px auto 0;
      }
      picture {
        display: block;
        margin-top: 56px;
      }
    }

    .block-list {
      margin-top: 52px;
      h4 {
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.6;
        letter-spacing: .05em;
        strong {
          color: $theme-green;
        }
      }

      ol {
        margin-top: 64px;
        counter-reset: num 0;
        @include pc {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 80px 15px;
        }
        li {
          counter-increment: num 1;
        }
        .outer {
          display: grid;
          grid-template-columns: 97px 1fr;
          position: relative;
          &::before {
            content: '0' counter(num);
            color: #0FD5F0;
            @include Barlow(400);
            font-size: 50px;
            letter-spacing: .05em;
            line-height: 1;
            margin-top: -3px;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: #0FD5F0;
            left: 75px;
            top: 3px;
          }
          dt {
            font-size: 30px;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: .1em;
          }
          dd {
            margin-top: 20px;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: .05em;
          }
        }
        a {
          dt {
            transition: $transition-base;
          }
          dd {
            padding-right: 106px;
            position: relative;
            max-inline-size: max-content;
            img {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              width: 50px;
              transition: $transition-base;
            }
          }
          &:hover {
            dt {
              color: #0FD5F0;
            }
            img {
              transform: translateX(5px);
            }
          }
        }
      }
    }

    .block-second {
      margin-top: 100px;
      .lead {
        margin-top: 25px;
        font-size: 16px;
      }
      picture {
        margin: 38px auto 0;
        display: block;
        max-width: 804px;
      }
      .note {
        margin-top: 39px;
        font-size: 14px;
      }
    }

    .breadcrumbs {
      margin-top: 87px;
    }

    @include sp {
      .block-main .lead_wrap {
        margin-top: 54px;
        h4 {
          strong {
            font-size: 35px;
          }
          small {
            margin-top: 12px;
            font-size: 14px;
          }
        }
        p {
          margin-top: 36px;
          font-size: 14px;
        }
        picture {
          margin: 45px -15px 0;
        }
      }
  
      .block-list {
        margin-top: 65px;
        h4 {
          text-align: center;
          font-size: 18px;
          line-height: 1.8;
          margin-inline: -20px;
        }
  
        ol {
          margin: 70px -15px 0;
          li {
            + li {
              margin-top: 40px;
            }
          }
          .outer {
            grid-template-columns: 70px 1fr;
            &::before {
              font-size: 36px;
            }
            &::after {
              left: 56px;
              top: 3px;
            }
            dt {
              font-size: 22px;
            }
            dd {
              margin-top: 12px;
              font-size: 12px;
            }
          }
          a {
            dd {
              padding-right: 74px;
              img {
                width: 36px;
              }
            }
          }
        }
      }
  
      .block-second {
        margin-top: 75px;
        .lead {
          margin-top: 25px;
          font-size: 13px;
        }
        picture {
          margin-top: 30px;
        }
        .note {
          margin-top: 30px;
          font-size: 11px;
        }
      }
  
      .breadcrumbs {
        margin-top: 77px;
      }
    }

  }

  &.is--article-16 {
    h5 {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: .05em;
    }

    .block-main .lead_wrap {
      margin-top: 66px;
      p {
        margin-top: 30px;
      }
    }

    .block-content {
      margin-top: 110px;
      .unit {
        + .unit {
          margin-top: 72px;
        }
      }

      .service_ttl {
        + p {
          margin-top: 38px;
        }
      }

      h5 {
        margin-top: 22px;
        small {
          font-size: 16px;
          white-space: nowrap;
        }
        + p {
          margin-top: 30px;
        }
      }

      .card {
        margin-top: 25px;
        @include pc {
          display: grid;
          column-gap: 40px;
          grid-template-columns: 1fr 362px;
        }
      }
    }

    .breadcrumbs {
      margin-top: 150px;
    }

    @include sp {
      h5 {
        font-size: 18px;
        line-height: 1.5;
      }
  
      .block-main .lead_wrap {
        margin-top: 54px;
        p {
          margin-top: 27px;
        }
      }
  
      .block-content {
        margin-top: 72px;
        .unit {
          + .unit {
            margin-top: 60px;
          }
        }
  
        .service_ttl {
          + p {
            margin-top: 38px;
          }
        }
  
        h5 {
          margin-top: 30px;
          small {
            font-size: 13px;
          }
          + p {
            margin-top: 27px;
          }
        }
  
        .card {
          margin-top: 22px;
          picture {
            display: block;
            margin-top: 25px;
          }
        }
      }
  
      .breadcrumbs {
        margin-top: 54px;
      }
    }
  }

}