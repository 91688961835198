body.is--aboutus {
  .rwd002-container {
    p {
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: .05em;
      @include sp {
        font-size: 13px;
      }
    }
  }

  .line_ttl {
    font-size: 25px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: .22em;
    padding-bottom: 30px;
    border-bottom: 1px solid #E3E3E3;
  }

  .block-lead {
    .area1 {
      h4 {
        font-size: 25px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: .15em;
        margin-top: 40px;
      }
      .unit {
        margin-top: 33px;
        @include pc {
          display: grid;
          grid-template-columns: 1fr 38%;
          column-gap: 32px;
        }
      }
      .img_wrap {
        p {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          column-gap: 20px;
          margin-top: 40px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1;
          strong {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: .22em;
          }
        }
      }
    }

    .area2 {
      margin-top: 60px;
      > p {
        margin-top: 24px;
      }
      > picture {
        display: block;
        margin-top: 38px;
      }
      .unit {
        margin-top: 56px;
        @include pc {
          max-width: 873px;
          display: grid;
          grid-template-columns: 1fr 179px;
          align-items: center;
          column-gap: 130px;
        }
      }
    }
  }

  .bn_wrap {
    margin: 125px auto 0;
    max-width: 800px;
  }

  .block-summary {
    margin-top: 123px;
    table {
      display: block;
      margin-top: 60px;
      tbody {
        display: block;
      }
      tr {
        display: grid;
        align-items: baseline;
        grid-template-columns: 140px 1fr;
        column-gap: 10px;
        padding-left: 22px;
        position: relative;
        padding-bottom: 15px;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
        }
        &::before {
          width: 100%;
          background-color: #E9E9E9;
        }
        &::after {
          width: 147px;
          background-color: #C0C1C1;
        }
        + tr {
          margin-top: 15px;
        }
      }
      th, td {
        display: block;
      }
      th {
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: .1em;
      }
      td, a {
        font-weight: 500;
        line-height: 2;
        letter-spacing: .15em;
      }
      a {
        color: $font-color;
      }
    }
    picture {
      display: block;
      max-width: 788px;
      margin: 35px 0 0 auto;
    }
  }

  .breadcrumbs {
    margin-top: 120px;
  }

  footer {
    margin-top: 64px;
  }

  @include sp {
    .line_ttl {
      font-size: 20px;
      padding-bottom: 19px;
    }
  
    .block-lead {
      .area1 {
        h4 {
          font-size: 20px;
          margin-top: 22px;
          @include max(390px){
            font-size: 18px;
          }
        }
        .unit {
          margin-top: 25px;
        }
        .img_wrap {
          margin-top: 25px;
          p {
            column-gap: 20px;
            margin-top: 18px;
            font-size: 13px;
            letter-spacing: 0;
            strong {
              font-size: 20px;
              letter-spacing: .22em;
            }
          }
        }
      }
  
      .area2 {
        margin-top: 56px;
        > picture {
          margin-top: 33px;
        }
        .unit {
          margin-top: 50px;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          row-gap: 30px;
          picture {
            width: 143px;
          }
        }
      }
    }
  
    .bn_wrap {
      margin-top: 80px;
      padding-inline: 35px;
    }
  
    .block-summary {
      margin-top: 80px;
      table {
        margin-top: 25px;
        tr {
          grid-template-columns: 80px 1fr;
          column-gap: 8px;
          padding-inline: 0;
          padding-bottom: 8px;
          &::after {
            width: 77px;
          }
          + tr {
            margin-top: 8px;
          }
        }
        th, td, a {
          font-size: 10px;
        }
        td, a {
          line-height: 2.3;
          font-size: 10px;
        }
      }
      picture {
        margin-top: 32px;
      }
    }
  
    .breadcrumbs {
      margin-top: 56px;
    }

    footer {
      margin-top: 56px;
    }
  }
}