@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.rwd002-footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  @include media-up(lg) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &__title{
    letter-spacing: 0.16em;
    margin-right: 1em;
    font-family: 'Lato', $font-family-sans-serif;
  }
  &__btn{
    @extend .btn;
    font-size: 1.8em;
    margin: 0 0.1em;
    line-height: 1;
    transition: $transition-base;
  }
  &__btn.is-fb{
    &:hover,&:focus{
      color: $sns-facebook-bg;
    }
  }
  &__btn.is-tw{
    &:hover,&:focus{
      color: $sns-twitter-bg;
    }
  }
  &__btn.is-line{
    &:hover,&:focus{
      color: $sns-line-bg;
    }
  }
}

.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px;
  width: 100px;
  height: 100px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $body-color;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  border: 1px solid $body-color;
  background-color: $white;

  @include media-up(lg) {
    right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    right: calc(50% - 50px);
  }
  &:before {
    content: "";
    display: block;
    margin-bottom: 1em;
    width: 34px;
    height: 21px;
    background: svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.67 21.05"><g fill="none" stroke="#{$dark}" stroke-miterlimit="10"><path d="m.34 20.68 16.5-15 16.5 15"/><path d="m.34 15.65 16.5-15 16.5 15"/></g></svg>'
      )
      no-repeat center / contain;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 80px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer {
  background-color: $dark;
  color: $white;
  @include media-up(md) {
    padding: map-get($spacers, 5) map-get($spacers, 5) map-get($spacers, 3);
  }
  @include media-down(lg) {
    padding-top: 90px;
  }
  @include media-down(md) {
    padding: 90px map-get($spacers, 4) map-get($spacers, 3);
  }

  &__inner {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      min-height: 300px;
    }
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    @include media-up(lg) {
      flex-grow: 1;
      padding-right: map-get($spacers, 3);
    }
    @include media-down(lg) {
      margin-bottom: 2em;
    }
  }
  &__companyName {
    font-size: $font-size-base * 1.375;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
  }

  &__address {
    font-size: $font-size-sm;
    color: tint($dark, 50%);
    letter-spacing: 0.19em;
    font-feature-settings: "palt";
    &:last-child {
      margin-bottom: 0;
    }
    .zip,
    .addr2 {
      display: none;
    }
  }

  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: tint($dark, 30%);
    font-feature-settings: "palt";
  }

  &__copyright {
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 1em;
  }
}

.rwd002-footerNav {
  @include media-up(lg) {
    display: grid;
    grid-template: 1fr auto / 2fr 1fr;
    flex-basis: 60%;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
  }
  @include media-down(md) {
    display: block;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list {
    font-size: $font-size-base * 0.9375;
    color: darken($white, 20%);
    @include media-up(md) {
      column-count: 2;
      column-gap: 1em;
    }
    @include media-up(lg) {
      grid-column: 1;
      grid-row: 1 / span 2;
      column-gap: 0;
    }
    @include media-down(lg) {
      margin-right: 2.5em;
    }
    @include media-down(md) {
      flex-grow: 1;
      margin-right: 0;
      margin-left: -0.8em;
      margin-right: -0.8em;
      margin-bottom: 1.5em;
    }
  }
  &__sublist {
    font-size: $font-size-xs;
    color: darken($white, 40%);
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1;
    }
    @include media-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  @include media-up(md) {
    padding-right: 1em;
  }
  @include media-down(md) {
    position: relative;
    border: solid darken($white, 50%);
    border-width: 1px 0;
    & + & {
      border-top-width: 0;
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.3em 1em;
    letter-spacing: 0.18em;
    font-feature-settings: "palt";
    font-weight: $font-weight-bold;
    &:hover,
    &:focus {
      color: $white;
    }
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 0.3em;
      top: 1em;
      left: 0;
      background-color: darken($white, 20%);
    }
    @include media-down(md) {
      flex-grow: 1;
      flex-basis: calc(100% - 40px);
      padding: 0.55em 40px 0.55em 1.7em;
      &:before {
        top: 1.25em;
        left: 0.7em;
      }
    }
  }
}

.rwd002-footerNav-subitem {
  &__link {
    display: inline-block;
    padding: 0.3em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.16em;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}


footer {
  .contact_area {
    background: url(/assets/img/common/contact_bg@2x.jpg) no-repeat center / cover;
    @include pc {
      display: grid;
      place-content: center;
      height: 450px;
    }

    .inner {
      max-width: 800px;
      margin-inline: auto;
      padding: 40px 50px 37px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, .5);
      .ttl {
        text-align: center;
        strong, small {
          display: block;
          line-height: 1;
        }
        strong {
          @include Barlow(400);
          font-size: 45px;
          letter-spacing: .05em;
        }
        small {
          margin-top: 8px;
          font-weight: 500;
          letter-spacing: .03em;
        }
      }

      .lead_wrap {
        margin-top: 35px;
        ul {
          display: flex;
          justify-content: center;
          column-gap: 10px;
          li {
            font-size: 21px;
            font-weight: 700;
            letter-spacing: .05em;
            color: #fff;
            line-height: 1;
            padding-inline: 14px;
            line-height: 40px;
            background-color: #282828;
            border-radius: 20px;
          }
        }
        p {
          font-size: 22px;
          font-weight: 700;
          letter-spacing: .14em;
        }
      }

      .contact_wrap {
        margin-top: 26px;
        .tel {
          display: flex;
          align-items: center;
          column-gap: 12px;
          img {
            width: 29px;
          }
          a {
            @include Barlow(500);
            font-size: 38px;
            letter-spacing: .09em;
          }
        }
        .btn_wrap {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 22px;
            width: 336px;
            height: 77px;
            background-color: #32AF36;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: .1em;
            img {
              width: 26px;
            }
            &:hover {
              background-color: #57c95b;
            }
          }
        }
      }
      
      @include pc {
        .lead_wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px 15px;
        }
  
        .contact_wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 26px 40px;
        }

      }
    }
  }

  .foot_content {
    padding-block: 73px 25px;
    position: relative;
    .info_wrap {
      .logo {
        max-width: 420px;
        margin-inline: auto;
        a:hover {
          opacity: .7;
        }
      }
      .address {
        font-weight: 500;
        letter-spacing: .1em;
        line-height: 1;
        text-align: center;
        margin-top: 20px;
      }
    }

    nav {
      margin-top: 70px;
      a:hover {
        color: $theme-green;
      }
      @include pc {
        .main_list {
          max-width: 730px;
          margin-inline: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          row-gap: 15px;
        }
        li {
          padding-inline: 20px;
          position: relative;
          &::before {
            content: '|';
            position: absolute;
            left: -2px;
            top: 0;
          }
          &:nth-of-type(5), &:last-of-type {
            &::after {
              content: '|';
              position: absolute;
              right: -2px;
              top: 0;
            }
          }
        }
        a {
          font-weight: 500;
          letter-spacing: .05em;
        }
      }
    }

    .share_wrap {
      margin-top: 70px;
      p {
        @include Roboto(500);
        text-align: center;
        position: relative;
        line-height: 1;
        font-size: 14px;
        letter-spacing: .1em;
        padding-bottom: 21px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 30px;
          height: 1px;
          background-color: $font-color;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .sns_list {
        margin-top: 18px;
        display: flex;
        column-gap: 35px;
        justify-content: center;
        align-items: center;
        .icon {
          transition: $transition-base;
        }
      }
      .fb {
        width: 27px;
        aspect-ratio: 1 / 1;
        &:hover {
          fill: $sns-facebook-bg;
        }
      }
      .x {
        width: 29px;
        aspect-ratio: 9 / 8;
        &:hover {
          opacity: .7;
        }
      }
      .line {
        width: 27px;
        aspect-ratio: 27 / 26;
        &:hover {
          fill: $sns-line-bg;
        }
      }
    }

    .copy {
      margin-top: 66px;
      text-align: center;
      span {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .06em;
        line-height: 1;
      }
    }

    .login {
      text-align: center;
      margin-top: 32px;
      a {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: .06em;
        line-height: 1;
        color: #C9C9C9;
      }
    }
  }

  @include sp {
    .contact_area {
      padding: 65px 25px 59px;
      background-image: url(/assets/img/common/contact_bg_sp@2x.jpg);
  
      .inner {
        padding: 35px 25px 28px;
        .ttl {
          strong {
            font-size: 36px;
          }
          small {
            margin-top: 8px;
            font-size: 13px;
          }
        }
  
        .lead_wrap {
          margin-top: 32px;
          ul {
            column-gap: 8px;
            li {
              font-size: 16px;
              padding-inline: 14px;
              line-height: 30px;
              border-radius: 15px;
            }
          }
          p {
            font-size: 15px;
            text-align: center;
            margin-top: 15px;
          }
        }
  
        .contact_wrap {
          margin-top: 22px;
          line-height: 1;
          .tel {
            column-gap: 12px;
            justify-content: center;
            img {
              width: 23px;
            }
            a {
              font-size: 30px;
            }
          }
          .btn_wrap {
            margin-top: 24px;
            a {
              column-gap: 12px;
              width: 100%;
              height: 62px;
              font-size: 14px;
              img {
                width: 21px;
              }
            }
          }
        }
      }
    }
  
    .foot_content {
      padding-block: 50px 44px;

      .info_wrap {
        .logo {
          max-width: 257px;
        }
        .address {
          font-size: 11px;
          margin-top: 20px;
        }
      }
  
      nav {
        margin-top: 43px;
        .main_list {
          border-bottom: 1px solid rgba(167, 171, 175, .5);
          > li {
            border-top: 1px solid rgba(167, 171, 175, .5);
            &.service {
              display: grid;
              grid-template-columns: 1fr 36px;
              .slide_trigger {
                display: block;
                position: relative;
                height: 45px;
                background-color: transparent;
                border: none;
                &::before, &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 9px;
                  height: 1px;
                  background-color: $font-color;
                  inset: 0;
                  margin: auto;
                }
                &::after {
                  transform: rotate(-90deg);
                  transition: $transition-base;
                }
                &.open::after {
                  transform: rotate(0);
                }
              }
            }
            > a {
              display: flex;
              align-items: center;
              height: 45px;
              padding-left: 25px;
              box-sizing: border-box;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
            }
          }
        }

        .sub_list {
          grid-column: 1 / 3;
          padding-left: 25px;
          padding-bottom: 20px;
          display: none;
          li {
            line-height: 1;
            &::before {
              content: '-';
              margin-right: 4px;
            }
            & + li {
              margin-top: 10px;
            }
          }
          a {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: .06em;
          }
        }

        .other_list {
          margin-top: 28px;
          margin-right: 4%;
          display: flex;
          justify-content: center;
          column-gap: 25px;
          a {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: .06em;
            color: #A7ABAF;
          }
        }
      }
  
      .share_wrap {
        margin-top: 45px;
        p {
          font-size: 11px;
          padding-bottom: 15px;
          &::before {
            width: 24px;
          }
        }
        .sns_list {
          margin-top: 11px;
          column-gap: 25px;
        }
        .fb {
          width: 22px;
        }
        .x {
          width: 24px;
        }
        .line {
          width: 22px;
        }
      }
  
      .copy {
        margin-top: 66px;
        height: 10px;
        position: relative;
        span {
          font-size: 10px;
          white-space: nowrap;
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 0;
          transform: scale(.6) translateX(-83%);
        }
      }
  
      .login {
        margin-top: 29px;
      }
    } 
  }
}

.pagetop {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &.stop {
    position: absolute;
    top: 40px;
    bottom: auto;
  }
  a {
    display: block;
    position: relative;
    width: 60px;
    height: 180px;
    background-color: #000;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 56px;
      left: 0;
      right: 0;
      top: 25px;
      margin: auto;
    }
    &::before {
      background-color: #999;
    }
    &::after {
      background-color: $theme-green;
      transform-origin: top;
      animation: pagetopLine 1.8s infinite ease-in-out;
    }
    &:hover {
      background-color: #2f2e2e;
    }
    span {
      font-size: 11px;
      @include Roboto(400);
      color: #fff;
      position: absolute;
      transform: rotate(90deg);
      left: 0;
      bottom: 46px;
      letter-spacing: .1em;
      white-space: nowrap;
    }
  }
}

@keyframes pagetopLine {
  0% {
    transform: scaleY(10%);
    opacity: 0;
  }
  30% {
    transform: scaleY(10%);
    opacity: 1;
  }
  60% {
    transform: scaleY(100%);
  }
  100% {
    transform: scaleY(0);
  }
}