$gnav-height-md: 120px;
$gnav-height-xs: 55px;

#allbox {
  // ナビバーが画面固定されたときのクリアランス
  @include media-down(lg) {
    padding-top: 80px;
  }
}

.rwd002-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-left: map-get($spacers, 3);
  box-sizing: border-box;

  @include media-up(lg) {
    position: relative;
    padding-left: 100px;
  }

  @include media-down(lg) {
    position: fixed;
    height: 80px;
    top: 0;
    background-color: $white;
    padding-left: 57px;
    z-index: 100;
  }

  &__ribon {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 220px;
    background: url(/assets/img/common/head_ribbon.svg) no-repeat 0 / contain;
    z-index: 1;
    strong {
      position: absolute;
      color: #fff;
      left: 0;
      right: 0;
      top: 123px;
      line-height: 1;
      text-align: center;
      @include Barlow(500);
      font-size: 27px;
      letter-spacing: .05em;
    }
    @include sp {
      width: 45px;
      height: 132px;
      font-size: 16px;
      strong {
        top: 74px;
        font-size: 16px;
      }
    }
  }

  &__logo {
    margin: 0;
    width: min(100%, 341px);
    @include max(1200px){
      width: clamp(200px, 20vw, 260px);
    }
    a:hover {
      opacity: .7;
    }
    img {
      width: 100%;
    }
    @include sp {
      width: 231px;
    }
  }

}

.rwd002-header__drawerBtn {
  @extend .btn;
  height: 100%;
  position: relative;
  z-index: 101;
  
  @include media-down(lg) {
    width: 70px;
    background-color: fade-out($white, 0.5);
  }

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: $body-color;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}
