.breadcrumbs {
  ul {
    display: flex;
  }
  li {
    color: $theme-green;
    a {
      color: $font-color;
      &:hover {
        color: $theme-green;
      }
    }
    + li {
      &::before {
        content: '';
        display: inline-block;
        position: relative;
        width: 5px;
        height: 5px;
        box-sizing: border-box;
        border-right: 1px solid $theme-green;
        border-bottom: 1px solid $theme-green;
        transform: rotate(-45deg);
        margin-inline: 18px;
        bottom: 4px;
      }
    }
  }
  li, a {
    font-weight: 500;
    letter-spacing: .14em;
  }

  @include sp {
    li {
      + li {
        &::before {
          width: 4px;
          height: 4px;
          margin-inline: 10px;
          bottom: 3px;
        }
      }
    }
    li, a {
      font-size: 10px;
    }
  }
}